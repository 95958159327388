<template>
  <div id="print_area">

    <div id="print_target">
      <div id="print_content" class="receipt">
        <img src="@/statics/img/居家-收據-final.png" alt="">

        <div class="receipt_title table_value left">
          {{ receiptInfo.title }}
        </div>
        <div class="receipt_username table_value left">
          {{ receiptInfo.user_name }}
        </div>
        <div class="receipt_price table_value left">
          $ {{ receiptInfo.price }}
        </div>
        <div class="receipt_content table_value left">
          {{ receiptInfo.content }}
        </div>

        <div class="receipt_feeno table_value right">
          {{ receiptInfo.fee_no }}
        </div>
        <div class="receipt_userno table_value right">
          {{ receiptInfo.user_no }}
        </div>
        <div class="receipt_paytime table_value right">
          {{ receiptInfo.pay_time }}
        </div>
        <div class="receipt_paytype table_value right">
          {{ receiptInfo.pay_type }}
        </div>
      </div>
    </div>
    <!-- <table
      class="MsoTableGrid"
      border="0"
      cellspacing="0"
      cellpadding="0"
      width="888"
      style="margin: 0 auto;width:665.85pt;border-collapse:collapse;border:none"
    >
      <tbody>
        <tr style="height:73.1pt">
          <td width="367" colspan="2" style="width:275.45pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:73.1pt">
            <p class="MsoNormal" style="line-height:16.0pt">
              <span style="text-fit:203.0pt">
                <span style="font-size:14.0pt;font-family:標楷體;letter-spacing:.85pt">社團法人台灣</span>
              </span>
              <span style="text-fit:203.0pt">
                <span lang="ZH-HK" style="font-size:14.0pt;font-family:標楷體;letter-spacing:.85pt"
                >居家醫療醫學</span>
              </span>
              <span style="text-fit:203.0pt">
                <span style="font-size:14.0pt;font-family:標楷體;letter-spacing:.85pt">會</span></span>
            </p>
            <p class="MsoNormal" style="line-height:16.0pt">
              <span lang="EN-US" style="font-family:標楷體">Taiwan Home Medical Association</span></p>
            <p class="MsoNormal" style="line-height:16.0pt">
              <span style="font-size:11.0pt;font-family:標楷體">台中市北屯區環中東路二段
              <span lang="EN-US">255</span>號<span lang="EN-US">4</span>樓</span>
            </p>
            <p class="MsoNormal" style="line-height:16.0pt">
              <span style="font-family:標楷體">電話<span lang="EN-US">:04-24360305</span>；傳真
              <span
              lang="EN-US">:04-24362334</span>
                </span>
            </p>
          </td>
          <td width="114" valign="top" style="width:85.15pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:73.1pt">
            <p
              class="MsoNormal"
              align="center"
              style="margin-top:18.0pt;text-align:center;line-height:20.0pt"
            >
              <span style="position:relative;z-index:251667456">
                <span style="left:0px;position:absolute;left:-2px;top:-1px;width:96px;height:96px">
                  <img src="@/statics/img/居家醫療LOGO.png" alt="">
                </span>
              </span>
            </p>
          </td>
          <td width="407" colspan="3" style="width:305.25pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:73.1pt">
            <p class="MsoNormal">
              <span lang="EN-US" style="font-family:標楷體">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span style="font-family:標楷體">立案字號
                <span lang="EN-US">:</span>台內團字
              <span
              lang="EN-US">1080038224</span>號</span>
            </p>
            <p class="MsoNormal"><span lang="EN-US" style="font-family:標楷體">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span><span style="font-family:標楷體">統一編號<span lang="EN-US">:82652303</span></span>
            </p>
            <p class="MsoNormal"><span lang="EN-US" style="font-family:標楷體">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span><span style="font-family:標楷體">稅籍編號<span lang="EN-US">:470821452</span></span>
            </p>
          </td>
        </tr>
        <tr style="height:50.05pt">
          <td
            width="888"
            colspan="6"
            valign="top"
            style="width:665.85pt;padding:0cm 5.4pt 0cm 5.4pt;height:50.05pt"
          >
            <p class="MsoNormal" style="margin-top:9.0pt;text-indent:263.7pt;line-height:40.0pt">
              <u>
                <span style="font-size:30.0pt;font-family:標楷體">收<span lang="EN-US"> </span>據</span>
              </u>
            </p>
          </td>
        </tr>
        <tr style="height:38.05pt">
          <td width="481" colspan="3" valign="top" style="width:360.6pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:38.05pt">
            <p
              class="MsoNormal"
              style="text-align:justify;text-justify:inter-ideograph;line-height:20.0pt"
            >
              <span style="font-size:16.0pt;font-family:標楷體">
                抬<span lang="EN-US">&nbsp; </span>頭：</span>
            </p>
          </td>
          <td
            width="407"
            colspan="3"
            valign="top"
            style="width:305.25pt;padding:0cm 5.4pt 0cm 5.4pt;height:38.05pt">
            <p
              class="MsoNormal"
              style="text-align:justify;text-justify:inter-ideograph;line-height:20.0pt"
            >
              <span style="font-size:16.0pt;font-family:標楷體">收據編號：</span></p>
          </td>
        </tr>
        <tr style="height:38.05pt">
          <td width="481" colspan="3" valign="top" style="width:360.6pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:38.05pt">
            <p
              class="MsoNormal"
              style="text-align:justify;text-justify:inter-ideograph;line-height:20.0pt"
            >
              <span style="font-size:16.0pt;font-family:標楷體"
              >姓<span lang="EN-US">&nbsp; </span>名：</span>
            </p>
          </td>
          <td
            width="407"
            colspan="3"
            valign="top"
            style="width:305.25pt;padding:0cm 5.4pt 0cm 5.4pt;height:38.05pt"
          >
            <p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
          line-height:20.0pt"><span style="font-size:16.0pt;font-family:標楷體">會員編號：</span></p>
          </td>
        </tr>
        <tr style="height:38.05pt">
          <td width="481" colspan="3" valign="top" style="width:360.6pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:38.05pt">
            <p
              class="MsoNormal"
              style="text-align:justify;text-justify:inter-ideograph;line-height:20.0pt">
              <span style="font-size:16.0pt;font-family:標楷體"
              >金<span lang="EN-US">&nbsp; </span>額：</span>
            </p>
          </td>
          <td
            width="407"
            colspan="3"
            valign="top"
            style="width:305.25pt;padding:0cm 5.4pt 0cm 5.4pt;height:38.05pt"
          >
            <p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
          line-height:20.0pt"><span style="font-size:16.0pt;font-family:標楷體">繳費日期：</span></p>
          </td>
        </tr>
        <tr style="height:38.05pt">
          <td width="481" colspan="3" valign="top" style="width:360.6pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:38.05pt">
            <p
              class="MsoNormal"
              style="text-align:justify;text-justify:inter-ideograph;line-height:20.0pt"
            >
              <span style="font-size:16.0pt;font-family:標楷體"
              >項<span lang="EN-US">&nbsp; </span>目：</span>
            </p>
            <p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
          text-indent:63.2pt;line-height:20.0pt"><span lang="EN-US" style="font-size:
          16.0pt;font-family:標楷體">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
          text-indent:63.2pt;line-height:20.0pt"><span lang="EN-US" style="font-size:
          16.0pt;font-family:標楷體">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
          text-indent:63.2pt;line-height:20.0pt"><span lang="EN-US" style="font-size:
          16.0pt;font-family:標楷體">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
          text-indent:63.2pt;line-height:20.0pt"><span lang="EN-US" style="font-size:
          16.0pt;font-family:標楷體">&nbsp;</span></p>
          </td>
          <td
            width="407"
            colspan="3"
            valign="top"
            style="width:305.25pt;padding:0cm 5.4pt 0cm 5.4pt;height:38.05pt"
          >
            <p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
          line-height:20.0pt"><span style="font-size:16.0pt;font-family:標楷體">繳費方式：</span></p>
          </td>
        </tr>
        <tr style="height:19.7pt">
          <td
            width="888"
            colspan="6"
            valign="top"
            style="width:665.85pt;padding:0cm 5.4pt 0cm 5.4pt;height:19.7pt"
          >
            <p class="MsoNormal" style="line-height:22.0pt">
              <span lang="ZH-HK" style="font-family:標楷體"
              >備</span><span lang="EN-US" style="font-family:標楷體">&nbsp;
          </span><span lang="ZH-HK" style="font-family:標楷體">註：</span></p>
            <p class="MsoNormal" style="line-height:20.0pt">
              <span lang="EN-US" style="font-family:標楷體">1.</span>
              <span lang="ZH-HK" style="font-family:標楷體">依印花稅第六條第</span>
              <span lang="EN-US" style="font-family:標楷體">14</span>
              <span lang="ZH-HK" style="font-family:標楷體">款之規定</span>
              <span lang="EN-US" style="font-family:標楷體">,</span>
              <span lang="ZH-HK" style="font-family:標楷體">本捐款收據免貼印花稅票。</span>
            </p>
            <p class="MsoNormal" style="line-height:20.0pt">
              <span lang="EN-US" style="font-family:標楷體">2.</span>
              <span lang="ZH-HK" style="font-family:標楷體">依所得稅法第</span>
              <span lang="EN-US" style="font-family:標楷體">17</span>
              <span lang="ZH-HK" style="font-family:標楷體">條及</span>
              <span lang="EN-US" style="font-family:標楷體">36</span>
              <span lang="ZH-HK" style="font-family:標楷體">條之規定</span>
              <span lang="EN-US" style="font-family:標楷體">,</span>
              <span lang="ZH-HK" style="font-family:標楷體">捐款部份得列為捐款人所得之扣除額。</span>
            </p>
            <p class="MsoNormal" style="line-height:20.0pt">
              <span lang="EN-US" style="font-family:標楷體">3.</span>
              <span lang="ZH-HK" style="font-family:標楷體">本收據由機器印出，如有塗改或未加蓋本醫學會收據專用章，概屬無效。</span>
            </p>
          </td>
        </tr>
        <tr style="height:72.55pt">
          <td width="142" valign="top" style="width:106.45pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:72.55pt">
            <p class="MsoNormal" style="margin-top:18.0pt;line-height:20.0pt">
              <span style="font-size:14.0pt;font-family:標楷體">收據專用章：</span>
            </p>
          </td>
          <td width="398" colspan="3" valign="top" style="width:298.7pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:72.55pt">
            <p class="MsoNormal" style="margin-top:18.0pt;line-height:20.0pt">
              <span
                style="position:absolute;z-index:-1895811072;margin-left:1px;
                margin-top:2px;width:86px;height:91px"
              >
                <img class="organization_stamp" src="@/statics/img/收據章.png" alt="">
              </span>
            </p>
          </td>
          <td width="116" valign="top" style="width:86.95pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:72.55pt">
            <p class="MsoNormal" style="margin-top:18.0pt;line-height:20.0pt">
              <span style="font-size:14.0pt;font-family:標楷體">&nbsp;理事長：</span></p>
          </td>
          <td width="232" valign="top" style="width:173.75pt;padding:0cm 5.4pt 0cm 5.4pt;
          height:72.55pt">
            <p class="MsoNormal" style="margin-top:18.0pt;line-height:20.0pt">
              <span style="position:absolute;z-index:-1895810048;margin-left:1px;
              margin-top:13px;width:51px;height:53px">
                <img class="chairman_stamp" src="@/statics/img/理事長印章.png" alt="">
              </span>
            </p>
          </td>
        </tr>
        <tr height="0">
          <td width="142" style="border:none"></td>
          <td width="225" style="border:none"></td>
          <td width="114" style="border:none"></td>
          <td width="59" style="border:none"></td>
          <td width="116" style="border:none"></td>
          <td width="232" style="border:none"></td>
        </tr>
      </tbody>
    </table> -->

    <!-- <div id="pdf_receipt" class="pdf_box" ref="pdf_receipt">
      <div class="pdf_header">
        <div class="unit_address">
          <h4 class="pt-14">社團法人台灣居家醫療醫學會</h4>
          <p class="pt-12">Taiwan Home Medical Association</p>
          <p class="pt-11">台中市北屯區環中東路二段255號4樓</p>
          <p class="pt-12">電話:04-24360305；傳真:04-24362334</p>
        </div>
        <div class="unit_logo">
          <img src="@/statics/img/居家醫療LOGO.png" alt="">
        </div>
        <div class="unit_law">
          <p class="pt-12">立案字號:台內團字1080038224</p>
          <p class="pt-12">統一編號:82652303</p>
          <p class="pt-12">稅籍編號:470821452</p>
        </div>
      </div>
      <div class="pdf_content">
        <div class="pdf_title">
          <h2><span class="pt-30">收&nbsp;據</span></h2>
        </div>
        <div class="pdf_table">
          <table>
            <thead></thead>
            <tbody class="pt-16">
              <tr>
                <td class="table_key front">抬 頭：</td>
                <td class="table_val front">{{ receiptInfo.title }}</td>
                <td class="table_key">收據編號：</td>
                <td class="table_val">{{ receiptInfo.fee_no }}</td>
              </tr>
              <tr>
                <td class="table_key">姓 名：</td>
                <td class="table_val">{{ receiptInfo.user_name }}</td>
                <td class="table_key">會員編號：</td>
                <td class="table_val">{{ receiptInfo.user_no }}</td>
              </tr>
              <tr>
                <td class="table_key">金 額：</td>
                <td class="table_val">${{ receiptInfo.price }}</td>
                <td class="table_key">繳費日期：</td>
                <td class="table_val"
                >{{ receiptInfo.pay_time }}</td>
              </tr>
              <tr>
                <td class="table_key">項 目：</td>
                <td class="table_val lh15"
                >{{ receiptInfo.content }}</td>
                <td class="table_key">繳費方式：</td>
                <td class="table_val">{{ receiptInfo.pay_type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="bottom">
        <div class="pdf_reminder">
          <p class="pt-12">備 註：</p>
          <p class="pt-12">1.依印花稅第六條第14款之規定，本捐款收據免貼印花稅票。</p>
          <p class="pt-12">2.依所得稅法第17條及36條之規定，捐款部分得列為捐款人所得之扣除額。</p>
          <p class="pt-12">3.本收據由機器印出，如有塗改或未加蓋本醫學會收據專用章，概屬無效。</p>
        </div>
        <div class="pdf_footer">
          <div class="sign_box">
            <div class="sign_name"><p class="pt-14">收據專用章:</p></div>
            <div class="sign_stamp">
              <img class="organization_stamp" src="@/statics/img/收據章.png" alt="">
            </div>
          </div>
          <div class="sign_box">
            <div class="sign_name"><p class="pt-14">理事長:</p></div>
            <div class="sign_stamp small">
              <img class="chairman_stamp" src="@/statics/img/理事長印章.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="print_btn txt-center noprint">
      <button class="btn main_font" @click="printTarget">列印收據</button>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import Canvas2Image from 'canvas2image';
// eslint-disable-next-line no-unused-vars
import html2pdf from 'html2pdf.js';

export default {
  name: 'receiptPDF',
  data() {
    return {
      receiptInfo: {},
    };
  },
  created() {
    this.receiptInfo = this.$cookies.get('receiptInfo');
  },
  // mounted() {
  //   const container = document.querySelector('#capture_box');
  //   html2canvas(document.querySelector('#capture')).then((canvas) => {
  //     container.appendChild(canvas);
  //   });
  // },
  methods: {
    printTarget() {
      window.print();
    },
    // convert2canvas() {
    //   const element = document.querySelector('#capture');
    //   const width = element.offsetWidth;
    //   const height = element.offsetHeight;
    //   const canvas = document.createElement('canvas');
    //   const scale = 2;
    //   canvas.width = width * scale;
    //   canvas.height = height * scale;
    //   canvas.getContext('2d').scale(scale, scale);
    //   const opts = {
    //     scale,
    //     canvas,
    //     width,
    //     height,
    //     useCORS: true,
    //   };

    //   html2canvas(element, opts).then((myCanvas) => {
    //     const context = myCanvas.getContext('2d');

    //     // 【重要】关闭抗锯齿
    //     context.mozImageSmoothingEnabled = false;
    //     context.webkitImageSmoothingEnabled = false;
    //     context.msImageSmoothingEnabled = false;
    //     context.imageSmoothingEnabled = false;

    //     const img = Canvas2Image.convertToJPEG(myCanvas, myCanvas.width, myCanvas.height);

    //     // document.body.appendChild(img);
    //     document.body.appendChild(myCanvas);

    //     img.style.width = `${myCanvas.width / 2}px`;
    //     img.style.height = `${myCanvas.height / 2}px`;

    //     // const dataURL = img.getAttribute('src');

    //     // const a = document.createElement('a');
    //     // document.body.appendChild(a);
    //     // a.href = dataURL;
    //     // a.download = '下載';
    //     // a.click();
    //   });
    // },
    // exportToPDF() {
    //   const element = document.getElementsByTagName('canvas')[0];
    //   console.log(element);
    //   // const element = this.$refs.pdf_to_print;
    //   const opt = {
    //     margin: 0,
    //     filename: `${this.receiptInfo.content}-收據.pdf`,
    //     image: { type: 'jpeg', quality: 1 },
    //     html2canvas: { dpi: 192, letterRendering: true },
    //     jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
    //   };
    //   html2pdf().from(element).set(opt).save();
    // },
  },
};
</script>

<style lang="scss">
header, footer {
  display: none !important;
}
body {
  padding-top: 0;
  // font-family: '標楷體', 'DFKai-SB', 'BiauKai', 'STKaiti' !important;
  background-color: rgba(gray, .1);
}
@media print {
  .noprint {
    display: none;
  }
}
</style>
